import React from "react";
import BImage from "gatsby-background-image";
import Loadable from "@loadable/component";

import useImage from "../hooks/use-image";
import Layout from "../components/common/layout";
import SEO from "../components/common/seo";
const Location = Loadable(
  () => import("../components/common/locationSection"),
  { fallback: <div>loading</div> }
);
const Form = Loadable(() => import("../components/contact/formSection"), {
  fallback: <div>loading</div>,
});

const ContactUs = () => {
  const { bgContactLaptop, bgContactDesktop } = useImage();

  const contactSources = [
    bgContactLaptop.sharp.fluid,
    { ...bgContactDesktop.sharp.fluid, media: `(min-width: 1439px)` },
  ];

  return (
    <Layout>
      <SEO
        title="Connect"
        description="We empower our people with the tools, training, and vision they need to outperform the competition."
        keywords={[""]}
      />
      <BImage
        fluid={contactSources}
        Tag="section"
        className="contact-us-hero text-white relative p-6 lg:pl-20 lg:pt-24"
      >
        <h1 className="text-xl relative z-10 font-medium 2xl:text-2xl">
          Contact Us
        </h1>
        <h2
          className="relative z-10 uppercase font-bold text-4xl md:text-5xl lg:text-6xl xl:text-8xl 3xl:text-9xl"
          id="connect"
        >
          Connect With Us
        </h2>
        <p
          id="contact-us"
          className="mt-6 relative z-10 leading-relaxed text-lg 2xl:text-xl max-w-2xl"
        >
          We’re here to help and answer any question you might have. We look
          forward to hearing from you.
        </p>
        <span className="gradient-overlay-left" />
      </BImage>
      <Form />
      <Location />
    </Layout>
  );
};

export default ContactUs;
